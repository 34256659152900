import React from 'react'
import { Card, Button, Image, Divider } from 'semantic-ui-react'
import profilePic from './profile-pic.jpg'

class Bio extends React.Component {
  render() {
    return (
      <Card className="center-block ep-mb-section">
        <Image src={profilePic} />
        <Card.Content>
          <Card.Header>Lefteris Pegiadis</Card.Header>
          <Card.Meta>Front-end Engineer</Card.Meta>
          <Card.Description>
            Lefteris is a front-end engineer living in Greece who enjoys
            crafting JavaScript{' '}
            <i className="fab fa-js-square ep-mr-8" style={{ fontSize: 18 }} />{' '}
            experiences.
            <br />
            <Divider />
            Usually tinkering with
            <div style={{ marginTop: 8, fontSize: 30 }}>
              <i className="fab fa-react ep-mr-8" />
              <i className="fab fa-vuejs ep-mr-8" />
              <i className="fab fa-node ep-mr-8" />
              <i className="fab fa-sass ep-mr-8" />
              <i className="fab fa-git ep-mr-8" />
              <i className="fab fa-docker" />
            </div>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button
            style={{ marginRight: 8 }}
            as="a"
            href="https://twitter.com/pegiadis"
            target="_blank"
            rel="noopener noreferrer"
            circular
            icon="twitter"
            color="twitter"
          />
          <Button
            as="a"
            href="https://www.linkedin.com/in/pegiadis/"
            target="_blank"
            rel="noopener noreferrer"
            circular
            icon="linkedin"
            color="linkedin"
          />
        </Card.Content>
      </Card>
    )
  }
}

export default Bio
